@use "./_mantine.scss" as *;
.wrapper {
    position: relative;

    gap: var(--mantine-spacing-xs);

    &::before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: var(--mantine-color-brand-5);

        opacity: 0.1;
    }
}

.progress {
    :global .mantine-SemiCircleProgress-label {
        bottom: var(--mantine-spacing-xs);
    }
}
