@use "./_mantine.scss" as *;
.nav {
    position: sticky;
    top: 0;
}

.item {
    display: flex;

    border-radius: var(--mantine-radius-xs);

    padding: var(--mantine-spacing-xs);

    text-align: left;
    font-weight: 500;
    font-size: var(--mantine-font-size-sm);
    text-decoration: none;

    &:focus {
        outline: none;
    }

    &[aria-disabled='true'] {
        pointer-events: none;
        opacity: 0.5;
    }
}

.itemActive {
    background-color: var(--mantine-color-gray-1);
}

.scrollTarget {
    position: relative;

    &::before {
        content: '';

        position: absolute;
        top: calc(50% - 2px);
        left: -8px;

        display: block;

        width: 4px;
        height: 4px;
        border-radius: 99px;

        background-color: var(--mantine-color-brand-4);

        opacity: 0;

        transition: opacity 400ms ease;
    }
}

.activeScrollTarget {
    &::before {
        opacity: 1;
    }
}
