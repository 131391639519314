@use "./_mantine.scss" as *;
.root {
    font-weight: 500;

    border-bottom: 1px dashed var(--mantine-color-gray-3);

    font-size: inherit;

    transition: border-color 200ms;

    &:hover {
        border-bottom-color: var(--mantine-color-primary-4);
    }
}
