@use "./_mantine.scss" as *;
.root {
    position: relative;

    background-color: var(--mantine-color-gray-2);
}

.editCover {
    position: absolute;
    bottom: var(--mantine-spacing-xs);
    right: var(--mantine-spacing-xs);
}
