@use "./_mantine.scss" as *;
.container {
    container-type: inline-size;

    &[data-light-teaser='true'] {
        .author,
        .secondary {
            display: none;
        }

        .title {
            font-size: var(--mantine-font-size-md);
            font-weight: 600;
        }

        .main {
            gap: 8px;
        }

        .inner {
            gap: var(--mantine-spacing-xs);
        }

        .card {
            padding: var(--mantine-spacing-xs);
        }
    }
}

.card {
    height: 100%;
}

.inner {
    display: grid;
    align-items: start;
    row-gap: var(--mantine-spacing-md);
    column-gap: var(--mantine-spacing-xs);

    grid-template-areas: 'image image' 'main main' 'secondary secondary';
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 75px;

    @container (min-width: 500px) {
        column-gap: var(--mantine-spacing-md);

        grid-template-areas: 'image main' 'image secondary';
        grid-template-rows: auto;
        grid-template-columns: 25% 75%;
    }

    @container (min-width: 900px) {
        grid-template-areas: 'image main secondary';
        grid-template-columns: 20% 45% 35%;
    }
}

.image {
    grid-area: image;

    position: relative;

    margin: calc(var(--mantine-spacing-md) * -1);
    margin-bottom: 0;

    @container (min-width: 500px) {
        margin: 0;

        img {
            aspect-ratio: 4 /3;
        }
    }

    &::after {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 1;

        background-color: rgba(0, 0, 0, 0.05);
    }
}

.content {
    gap: var(--mantine-spacing-md);
}

.main {
    grid-area: main;

    gap: var(--mantine-spacing-xs);

    .title {
        font-size: var(--mantine-font-size-lg);
        line-height: 1.2;
        font-weight: 700;
    }

    .author {
        font-size: var(--mantine-font-size-sm);
    }

    .description {
        word-break: break-word;
        font-size: var(--mantine-font-size-sm);
        line-height: 1.4;
    }

    .spoiler {
        :global .mantine-Spoiler-control {
            font-size: var(--mantine-font-size-sm);
        }
    }

    .tag {
        &[data-clickable='true'] {
            cursor: pointer;

            &:hover {
                background-color: var(--mantine-color-gray-3);
            }
        }
    }
}

.secondary {
    grid-area: secondary;
}

.actions {
    grid-area: actions;

    gap: var(--mantine-spacing-xs);
}
