@use "./_mantine.scss" as *;
.controls {
    --control-size: 32px;

    padding: 0;
    margin-left: calc(var(--control-size) * -1 / 2);
    margin-right: calc(var(--control-size) * -1 / 2);
}

.control {
    width: var(--control-size);
    height: var(--control-size);

    color: var(--mantine-color-brand-5);
    border: none;

    box-shadow: 0 0 10px 2px rgba(#000, 0.1);

    transition: all 200ms;

    &:hover {
        background-color: var(--mantine-color-brand-5);
        color: white;
    }

    &[data-inactive='true'] {
        visibility: hidden;
    }
}

.slide {
    user-select: none;

    > * {
        height: 100%;
    }
}
