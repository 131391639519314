@use "./_mantine.scss" as *;
.root {
    display: flex;
    flex-direction: column;
}

.message {
    display: flex;
    gap: var(--mantine-spacing-md);
    flex-direction: column;
    align-items: start;

    border-radius: var(--mantine-radius-sm);
    background-color: var(--mantine-color-white);
    box-shadow: var(--mantine-shadow-xl);

    overflow: hidden;

    padding: var(--mantine-spacing-sm);

    font-size: var(--mantine-font-size-sm);

    &.highlighted {
        background-image: var(--default-gradient);

        color: var(--mantine-color-white);
    }

    @media (min-width: $mantine-breakpoint-xs) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding-left: var(--mantine-spacing-md);
    }
}
