@use "./_mantine.scss" as *;
.root {
    height: 100%;
    border: 1px solid var(--mantine-color-gray-2);

    transition: border 0.2s;

    &[data-active='true'] {
        border: 2px solid var(--mantine-color-primary-6);
    }
}
