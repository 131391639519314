@use "./_mantine.scss" as *;
.overlay {
    z-index: 99;

    padding: var(--mantine-spacing-xl);

    background-color: rgba(0, 0, 0, 0.1);
}

.card {
    position: sticky;
    top: calc(45px + var(--mantine-spacing-xl));
}
