@use "./_mantine.scss" as *;
.preview {
    position: relative;

    display: flex;

    align-items: center;
    gap: var(--mantine-spacing-xs);

    width: 100%;
    height: 36px;

    margin-top: 4px;
    padding: 0 var(--mantine-spacing-xs);

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-xs);
}

.previewText {
    flex-grow: 1;

    overflow: hidden;
    text-overflow: ellipsis;
}

.previewDelete {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 16px;
    height: 16px;

    svg {
        width: 14px;
        height: 14px;
    }
}
