@use "./_mantine.scss" as *;
.root {
    cursor: pointer;
    position: relative;

    border: 2px dashed var(--mantine-color-gray-3);

    color: var(--mantine-color-gray-5);
    background-color: transparent;

    transition: background-color 200ms ease;

    &:hover {
        border-color: transparent;
        background-color: var(--mantine-color-white);
        outline: 1px solid var(--mantine-color-gray-3);
        outline-offset: -1px;

        .badge {
            opacity: 0;
        }

        .addBadge {
            opacity: 1;
        }

        .title {
            color: var(--mantine-color-brand-text);
        }
    }
}

.title {
    padding-right: 60px;
}

.addBadge,
.badge {
    position: absolute;
    top: 8px;
    right: 8px;

    transition: opacity 200ms ease;
    transition-delay: 200ms;
}

.addBadge {
    opacity: 0;
}
