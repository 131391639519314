@use "./_mantine.scss" as *;
.tooltip {
    box-shadow: var(--mantine-shadow-xl);
}

.chatBubbleWrapper {
    --animation-color: var(--mantine-color-primary-6);

    border-radius: 99px;

    animation-duration: 2s;

    &:hover {
        animation-play-state: paused;
    }

    [data-intercom-opened='true'] & {
        animation-play-state: paused;
    }
}

.dropdown {
    display: flex;
    flex-direction: column;

    align-items: stretch;

    height: 520px;
    width: 360px !important;

    padding: 0;

    border-top: 0;

    box-shadow: var(--mantine-shadow-xl);
}

.navigation {
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;

    justify-content: space-between;
    align-items: center;

    width: calc(100% + 2px);
    height: 42px;

    margin-left: -1px;

    padding: 0 var(--mantine-spacing-xs);
    padding-right: 5px;

    background-color: var(--mantine-color-gray-9);
    color: #ffffff;

    border-top-left-radius: var(--mantine-radius-default);
    border-top-right-radius: var(--mantine-radius-default);

    &[data-has-back-icon='true'] {
        padding-left: 5px;
    }
}

.navigationAction {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;

    svg {
        width: 16px;
        height: 16px;

        fill: #ffffff;
    }
}

.empty {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    gap: var(--mantine-spacing-md);
}

.emptyHeader {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    gap: var(--mantine-spacing-xs);

    font-size: var(--mantine-font-size-sm);

    text-align: center;
    text-wrap: balance;

    svg {
        width: 40px;
        height: 40px;
    }
}

.emptyContent {
    flex-grow: 0;
    flex-shrink: 0;
}

.suggestion {
    display: flex;

    align-items: center;
    gap: var(--mantine-spacing-sm);

    padding: var(--mantine-spacing-xs);

    font-size: var(--mantine-font-size-sm);
    text-align: left;

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-xs);

    transition: background-color 0.3s ease;

    svg {
        flex-grow: 0;
        flex-shrink: 0;

        width: 16px;
        height: 16px;
    }

    &:hover {
        background-color: var(--mantine-color-gray-0);
    }
}

.content {
    flex-grow: 1;
    flex-shrink: 1;

    padding: var(--mantine-spacing-md) var(--mantine-spacing-xs);
}

.overviewChannel {
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
        background-color: var(--mantine-color-gray-0);
    }
}

.messages {
    display: grid;
    gap: var(--mantine-spacing-xs);
}

.message {
    display: grid;
    grid-template-columns: 32px 1fr;

    align-items: flex-start;

    gap: var(--mantine-spacing-xs);
}

.messageHeader {
    margin-bottom: 4px;

    font-size: 12px;
    font-weight: 500;

    small {
        margin-left: 1em;

        font-size: 12px;
        font-weight: 400;

        opacity: 0;
        transition: opacity 0.15s;
    }

    .message:hover & {
        small {
            opacity: 0.5;
        }
    }
}

.composer {
    padding: var(--mantine-spacing-xs);
    padding-top: 0;

    > div > div:first-child {
        height: 50px;
        overflow: auto;
    }
}
