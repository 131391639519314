@use "./_mantine.scss" as *;
.header {
    --logo-size: 150px;
    --header-offset: 50px;

    margin-top: calc(-1 * var(--header-offset));
    margin-bottom: var(--mantine-spacing-xl);

    @media (max-width: $mantine-breakpoint-xs) {
        flex-direction: column;

        margin-top: calc(var(--logo-size) * -1 + var(--mantine-spacing-xl));
    }
}

.info {
    flex-grow: 1;
    gap: var(--mantine-spacing-sm);

    margin-top: calc(var(--header-offset) + var(--mantine-spacing-sm));

    h1 {
        font-size: 32px !important;
    }

    @media (max-width: $mantine-breakpoint-xs) {
        margin-top: var(--mantine-spacing-xs);

        flex-direction: column;
        align-items: flex-start;
    }
}

.infoLeft {
    flex-grow: 1;
}

.infoRight {
    align-self: flex-start;
    align-items: flex-end;
    justify-content: center;
    gap: var(--mantine-spacing-sm);

    min-height: 38px;

    @media (max-width: $mantine-breakpoint-xs) {
        align-items: flex-start;
        justify-content: flex-start;

        min-height: auto;
    }
}

.booth {
    position: absolute;
    top: 6px;
    right: 6px;
}

.logoWrapper {
    position: relative;

    display: flex;
    flex-direction: row;

    margin-right: var(--mantine-spacing-sm);
}

.logo {
    position: relative;

    width: var(--logo-size);
    height: var(--logo-size);
    border: 1px solid var(--mantine-color-gray-3);
    background-color: var(--mantine-color-white);

    padding: 8px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.editLogo {
    @media (min-width: $mantine-breakpoint-xs) {
        position: absolute;
        left: 50%;
        bottom: 0;

        transform: translateX(-50%) translateY(50%);
    }
}
