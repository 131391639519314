@use "./_mantine.scss" as *;
.item {
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    aspect-ratio: 1 / 1;

    padding: var(--mantine-spacing-sm);

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-sm);
    background-color: var(--mantine-color-white);

    text-align: center;
    font-weight: 500;
    line-height: 1.2;

    transition: box-shadow 200ms;

    &.finish {
        pointer-events: none;

        background-color: var(--mantine-color-green-0);
        color: var(--mantine-color-green-9);
        border-color: rgba(0, 0, 0, 0.05);

        .icon {
            color: var(--mantine-color-green-9);
            opacity: 0.1;
        }
    }

    &[data-button] {
        &:hover {
            border-color: transparent;
            box-shadow: var(--mantine-shadow-xl);

            svg {
                transform: scale(1.1);
            }
        }
    }

    &[data-completed='true'] {
        pointer-events: none;

        color: var(--mantine-color-gray-5);
        border: none;
    }
}

.icon {
    --offset: -20px;

    position: absolute;
    z-index: 0;

    color: var(--mantine-color-gray-0);

    svg {
        width: 100px;
        height: 100px;

        transition: transform 200ms;
    }

    [data-icon-position='sw'] & {
        bottom: var(--offset);
        left: var(--offset);
    }

    [data-icon-position='se'] & {
        bottom: var(--offset);
        right: var(--offset);
    }

    [data-icon-position='nw'] & {
        top: var(--offset);
        left: var(--offset);
    }

    [data-icon-position='ne'] & {
        top: var(--offset);
        right: var(--offset);
    }

    [data-icon-position='n'] & {
        top: var(--offset);
    }

    [data-icon-position='s'] & {
        bottom: var(--offset);
    }

    [data-icon-position='w'] & {
        left: var(--offset);
    }

    [data-icon-position='e'] & {
        right: var(--offset);
    }
}

.label {
    z-index: 1;
}

.checkbox {
    position: absolute;
    top: 8px;
    left: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;
    border-radius: 50%;

    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-5);

    &[data-button] {
        cursor: pointer;

        &:hover {
            background-color: var(--mantine-color-gray-2);
        }
    }

    svg {
        transform: none !important;
    }

    [data-completed='true'] & {
        background-color: var(--mantine-color-green-6);
        color: var(--mantine-color-white);
    }
}
