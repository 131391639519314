@use "./_mantine.scss" as *;
.controls {
    .control {
        &:first-child {
            transform: translateX(-50%);
        }

        &:last-child {
            transform: translateX(50%);
        }
    }
}

.control {
    opacity: 1;
    border: none;

    box-shadow: var(--mantine-shadow-xs);

    color: var(--mantine-color-gray-5);

    &[data-inactive='true'] {
        visibility: hidden;
    }

    svg {
        width: 10px;
        height: 10px;
    }
}
