@use "./_mantine.scss" as *;
.option {
    &[data-combobox-selected='true'] {
        color: var(--mantine-color-black);
        background-color: var(--mantine-color-gray-2);
    }
}

.search {
    position: sticky;
    top: 0;
}
