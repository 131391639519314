@use "./_mantine.scss" as *;
.section {
    grid-column: span 3;

    + .section {
        margin-top: 4px;
        border-top: 1px solid var(--mantine-color-gray-2);
        padding-top: 4px;
    }
}
