@use "./_mantine.scss" as *;
.root {
    position: relative;

    &,
    .inner {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
    }

    background-color: #ffffff;
    color: var(--mantine-color-gray-8);
    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-sm);
    padding-left: var(--mantine-spacing-xs);
    padding-right: var(--mantine-spacing-xs);

    outline: 2px solid transparent;
    outline-offset: 0;

    &:active {
        outline-color: transparent;
    }

    &[data-selected='true'] {
        outline: 2px solid #000000;
        outline-offset: 0;
    }

    &[data-readOnly='true'] {
        outline: 2px solid transparent !important;
        pointer-events: none;
    }

    &[data-disabled='true'] {
        pointer-events: none;

        &:not([data-selected='true']) {
            background-color: var(--mantine-color-gray-1);
        }
    }

    svg,
    img {
        width: 100% !important;
        height: 100% !important;
    }

    &.square {
        aspect-ratio: 1 / 1;
    }

    .badge {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -6px);

        display: inline-flex;
    }

    .label {
        margin-top: 6px;
        font-size: var(--mantine-font-size-xs);
        line-height: 1.2;
        text-align: center;
        color: var(--mantine-color-gray-7);
    }
}
