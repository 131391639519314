@use "./_mantine.scss" as *;
.root {
    padding: var(--mantine-spacing-xl);

    gap: var(--mantine-spacing-xl);

    @media (max-width: $mantine-breakpoint-xs) {
        padding: var(--mantine-spacing-md);
    }
}

.home {
    > [data-section] {
        &:nth-of-type(even) {
            --section-padding: var(--mantine-spacing-xl);

            position: relative;

            margin-left: calc(var(--section-padding) * -1);
            margin-right: calc(var(--section-padding) * -1);

            padding: var(--section-padding);

            &:first-child {
                margin-top: calc(var(--section-padding) * -1);
            }

            &:last-child {
                margin-bottom: calc(var(--section-padding) * -1);
            }

            @media (max-width: $mantine-breakpoint-xs) {
                --section-padding: var(--mantine-spacing-md);
            }

            &::before {
                content: '';

                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                background: var(--mantine-color-brand-5);

                opacity: 0.1;
            }
        }
    }
}
