@use "./_mantine.scss" as *;
.root {
    position: relative;

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-sm);

    overflow: hidden;

    &[data-shadow='true'] {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    }

    &[data-overlay='true'] {
        border-color: rgba(0, 0, 0, 0.1);

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(black, 0.1);
        }
    }
}

.badge {
    position: absolute;
    top: 8px;
    right: 8px;
}
